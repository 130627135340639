@font-face {
  font-family: "Futura Bold";
  src: url("../fonts/futura-bold.otf") format("opentype"),
    url("../fonts/futura-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Futura Regular";
  src: url("../fonts/futura-medium.otf") format("opentype"),
    url("../fonts/futura-medium.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

td {
  width: 25vw;
}

td:nth-child(2n) {
  background-color: #f8f8f8;
}

td:nth-child(2n + 1) {
  background-color: white;
}

td {
  padding: 40px 20px;
  font-size: 20px;
  vertical-align: top;
}

.position-region {
  margin-bottom: 20px;
  padding: 0 0px;
  width: 100%;
}

.region-header {
  background-color: #005a9e;
  padding: 20px 20px;
  color: white;
  font-size: 22px;
}

.region-header-special {
  background-color: #006651;
  padding: 20px 20px;
  color: white;
  font-size: 22px;
}

body {
  background-color: #ededed;
  font-family: "Futura Regular";
  color: #01343e;
}

.inner-table-header {
  font-family: "Futura Bold";
  font-weight: bold;
  font-size: 20px;
}

.name-of-ship {
  font-size: 20px;
  font-weight: bold;
  font-family: "Futura Bold";
}

#header-12 {
  background-color: #006651;
}

@media screen and (max-width: 1000px) {
  td {
    text-align: center;
    width: 100%;
    width: 100vw !important;
  }
  tr {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px;
  }

  .region-header {
    width: 100vw !important;
    text-align: center;
  }
}
