* {
  margin: 0;
  padding: 0;
}

body {
  /* background-color: rgb(246, 246, 246); */
}

.link {
  text-decoration: none !important;
  color: inherit !important;
}

.App::-webkit-scrollbar {
  display: none !important;
}

body::-webkit-scrollbar {
  display: none !important;
}

.App {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* background-color: rgb(246, 246, 246); */
}
